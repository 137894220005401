import { getActiveFeatures } from './getActiveFeatures'

export const calculateActiveFeatures = (userInputData, featureslist) => {
    try {
        let arrayOfTrueConditions = getActiveFeatures(userInputData, featureslist)
        return activateDeactivateFeatures(arrayOfTrueConditions, featureslist)
    } catch (error) {
        console.log(error)
    }
}

const activateDeactivateFeatures = (arrayWithFeaturesToActivate, featureslist) => {
    let tempFeatures = { ...featureslist }
    Object.keys(tempFeatures).map((key) => {
        if (arrayWithFeaturesToActivate.includes(key)) {
            if (
                [key][0] === 'streamingSQL2' ||
                [key][0] === 'streamingSQL3' ||
                [key][0] === 'streamingSQL4'
            ) {
                tempFeatures.streamingSQL.active = true
                tempFeatures.streamingSQL.image = `../../images/features/streamingSQLActive.svg`
            }
            tempFeatures[key].active = true
            tempFeatures[key].image = `../../images/features/${key}Active.svg`
        } else {
            tempFeatures[key].active = false
            tempFeatures[key].image = `../../images/features/${key}Unactive.svg`
        }
    })
    return tempFeatures
}
