import { useState } from 'react'
import landing_phase from '../../resources/images/kafka_calculator/phaseOnLanding.png'
import data_exploration_unactive from '../../resources/images/kafka_calculator/features/data_exploration_unactive.svg'
import deploying_connectors_unactive from '../../resources/images/kafka_calculator/features/deploying_connectors_unactive.svg'
import flows_monitoring_unactive from '../../resources/images/kafka_calculator/features/flows_monitoring_unactive.svg'
import governance_unactive from '../../resources/images/kafka_calculator/features/governance_unactive.svg'
import infra_monitoring_unactive from '../../resources/images/kafka_calculator/features/infra_monitoring_unactive.svg'
import platform_administration_unactive from '../../resources/images/kafka_calculator/features/platform_administration_unactive.svg'
import schema_management_unactive from '../../resources/images/kafka_calculator/features/schema_management_unactive.svg'
import security_unactive from '../../resources/images/kafka_calculator/features/security_unactive.svg'
import streaming_sql_unactive from '../../resources/images/kafka_calculator/features/streaming_sql_unactive.svg'

const calculatorLogic = () => {
    const [calculatorData, setCalculatorData] = useState({
        kafkaProjects: 1,
        kafkaDevs: 1,
        kafkaManagers: 1,
        ratePerDay: 500,
        dataSources: 0,
        levelOfSkill: 'Not-very',
        criticalServices: false,
    })

    const {
        kafkaProjects,
        kafkaDevs,
        kafkaManagers,
        ratePerDay,
        dataSources,
        levelOfSkill,
        criticalServices,
    } = calculatorData

    const [initialLoad, setInitialLoad] = useState({
        initialLoader: true,
    })

    const { initialLoader } = initialLoad

    const [currentPhase, setCurrentPhase] = useState({
        imagePhase: landing_phase,
        phaseTitle: null,
        phaseText: null,
        phaseNumber: null,
        loader: null,
    })

    const { imagePhase, phaseTitle, phaseText, phaseNumber, loader } = currentPhase

    const [tableCosts, setTableCosts] = useState({
        valuePerYear: null,
        ctaText: '',
        ctaLinksTo: '',
        engineeringHoursToDevelopTools: null,
        engineeringManDaysToDevelopTools: null,
        CostOfDIYDevelopment: null,
        DaysToIntegrateWithLenses: null,
        DaysToIntegrate: null,
        TotalDevelopmentCost: null,
        ROILenses: null,
        ROIDIY: null,
    })

    const {
        valuePerYear,
        ctaText,
        ctaLinksTo,
        engineeringHoursToDevelopTools,
        engineeringManDaysToDevelopTools,
        CostOfDIYDevelopment,
        DaysToIntegrateWithLenses,
        DaysToIntegrate,
        TotalDevelopmentCost,
        ROILenses,
        ROIDIY,
    } = tableCosts

    const [features, setFeatures] = useState({
        dataExploration: {
            name: 'Data Exploration',
            useCase: 'Data Exploration',
            hoursRequiredToBuild: 528,
            // Always true
            requirements: ['kafkaProjects > 0'],
            active: false,
            image: data_exploration_unactive,
        },
        streamingSQL: {
            name: 'Streaming SQL',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 1056,
            requirements: [
                'kafkaProjects > 1',
                'kafkaDevs > 3',
                'dataSources > 2',
                'levelOfSkill === Not-very',
            ],
            active: false,
            image: streaming_sql_unactive,
        },
        streamingSQL2: {
            name: 'Streaming SQL',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 1056,
            requirements: [
                'kafkaProjects > 1',
                'kafkaDevs > 5',
                'dataSources > 4',
                'levelOfSkill === A-bit',
            ],
            active: false,
            image: streaming_sql_unactive,
        },
        streamingSQL3: {
            name: 'Streaming SQL',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 1056,
            requirements: ['kafkaProjects > 2', 'kafkaDevs > 10'],
            active: false,
            image: streaming_sql_unactive,
        },
        streamingSQL4: {
            name: 'Streaming SQL',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 1056,
            requirements: ['kafkaProjects > 1', 'kafkaDevs > 10'],
            active: false,
            image: streaming_sql_unactive,
        },
        deployingConnectors: {
            name: 'Deploying Connectors',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 704,
            requirements: [
                'kafkaProjects > 3',
                'kafkaDevs > 5',
                'dataSources > 3',
                'levelOfSkill === Not-very',
            ],
            active: false,
            image: deploying_connectors_unactive,
        },
        deployingConnectors2: {
            name: 'Deploying Connectors',
            useCase: 'Data Processing',
            hoursRequiredToBuild: 704,
            requirements: ['kafkaDevs > 2', 'dataSources > 6'],
            active: false,
            image: deploying_connectors_unactive,
        },
        infrastructureMonitoring: {
            name: 'Infra Monitoring',
            useCase: 'Monitoring & Observability',
            hoursRequiredToBuild: 100,
            requirements: ['criticalServices === true'],
            active: false,
            image: infra_monitoring_unactive,
        },
        flowsMonitoring: {
            name: 'Flows Monitoring',
            useCase: 'Monitoring & Observability',
            hoursRequiredToBuild: 528,
            requirements: ['dataSources > 3', 'criticalServices === true'],
            active: false,
            image: flows_monitoring_unactive,
        },
        schemaManagement: {
            name: 'Schema mgt',
            useCase: 'Self-service Admin',
            hoursRequiredToBuild: 528,
            requirements: ['dataSources > 10'],
            active: false,
            image: schema_management_unactive,
        },
        platformAdministration: {
            name: 'Platform Administration',
            useCase: 'Self-service Admin',
            hoursRequiredToBuild: 352,
            requirements: ['kafkaProjects > 4', 'kafkaDevs > 5'],
            active: false,
            image: platform_administration_unactive,
        },
        security: {
            name: 'Security',
            useCase: 'Security & governance',
            hoursRequiredToBuild: 528,
            requirements: ['kafkaProjects > 1', 'kafkaDevs > 7', 'criticalServices === true'],
            active: false,
            image: security_unactive,
        },
        governance: {
            name: 'Governance',
            useCase: 'Security & governance',
            hoursRequiredToBuild: 528,
            requirements: ['kafkaProjects > 2', 'kafkaDevs > 10'],
            active: false,
            image: governance_unactive,
        },
    })

    const [chartData, setChartData] = useState({
        series: [],
        options: {
            title: {
                text: 'Use case',
                align: 'left',
                offsetY: 35,
                offsetX: 20,
                style: {
                    fontSize: '21px',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, Arial, sans-serif',
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '50%',
                    horizontal: true,
                },
            },
            dataLabels: {
                // enabled: false,
                // formatter: function (val, opts) {
                //   var label = opts.w.globals.labels[opts.dataPointIndex]
                //   var a = moment(val[0])
                //   var b = moment(val[1])
                //   var diff = b.diff(a, 'days')
                //   return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
                // },
                style: {
                    colors: ['#fff'],
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    show: true,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Roboto, Arial, sans-serif',
                        fontWeight: 800,
                    },
                },
                axisTicks: {
                    show: false,
                },
                position: 'top',
            },
            yaxis: {
                labels: {
                    show: true,
                    align: 'left',
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Roboto, Arial, sans-serif',
                        fontWeight: 500,
                        // cssClass: 'border bg-red',
                    },
                },
            },
            grid: {
                show: true,
                borderColor: '#e1e7f4',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
                // padding: {
                //   top: 0,
                //   right: 0,
                //   bottom: 0,
                //   left: 0,
                // },
            },
            fill: {
                colors: ['#6f39c3'],
                type: 'solid', // gradient ,pattern
            },
            noData: {
                text: 'Calculating',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
            },
        },
    })

    return {
        calculatorData,
        kafkaProjects,
        kafkaDevs,
        kafkaManagers,
        ratePerDay,
        dataSources,
        levelOfSkill,
        criticalServices,
        setCalculatorData,
        //
        initialLoader,
        setInitialLoad,
        //
        currentPhase,
        imagePhase,
        phaseTitle,
        phaseText,
        phaseNumber,
        loader,
        setCurrentPhase,
        //
        tableCosts,
        valuePerYear,
        ctaText,
        ctaLinksTo,
        engineeringHoursToDevelopTools,
        engineeringManDaysToDevelopTools,
        CostOfDIYDevelopment,
        DaysToIntegrateWithLenses,
        DaysToIntegrate,
        TotalDevelopmentCost,
        ROILenses,
        ROIDIY,
        setTableCosts,
        //
        features,
        setFeatures,
        //
        chartData,
        setChartData,
    }
}

export default calculatorLogic
