import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import moment from 'moment'

import Layout from '../components/Layout'
import $ from 'jquery'
import Spacing from '../components/partials/Spacing'
import HeaderSEO from '../components/partials/HeaderSEO'
// import ChartComponent from '../components/partials/ChartComponent'

import { StaticImage } from 'gatsby-plugin-image'
import phase_blur from '../resources/images/kafka_calculator/kafka_phase_blur.png'

import KafkaROIForm from '../components/partials/Kafka-roi-form'
import CalculatorInputForm from '../components/calculator/CalculatorInputForm'
import CalculatorLogic from '../components/calculator/CalculatorLogic.js'
import FeaturesTable from '../components/calculator/FeaturesTable'
import TableCosts from '../components/calculator/TableCosts'

import { calculateActiveFeatures } from '../helpers/kafkaCalculator/updateFeatures'
import { calculateActivePhase } from '../helpers/kafkaCalculator/updatePhase'
import { calculateTableCosts } from '../helpers/kafkaCalculator/updatetableCosts'
// import { calculateChartTimelines } from '../helpers/kafkaCalculator/updateChart'

declare const window: any

const kafkaCalculator = () => {
  const data = useStaticQuery(graphql`
    query kafkaCalculatorSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "kafkaCalculator" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)
  const pageSEO = data.allContentfulPages.edges[0].node
  const {
    // CalculatorData
    calculatorData,
    kafkaProjects,
    kafkaDevs,
    kafkaManagers,
    ratePerDay,
    dataSources,
    levelOfSkill,
    criticalServices,
    setCalculatorData,
    // Loader
    initialLoader,
    setInitialLoad,
    // Image/Current phase
    currentPhase,
    imagePhase,
    phaseTitle,
    phaseText,
    phaseNumber,
    loader,
    setCurrentPhase,
    // Table values
    tableCosts,
    valuePerYear,
    ctaText,
    ctaLinksTo,
    // engineeringHoursToDevelopTools,
    engineeringManDaysToDevelopTools,
    CostOfDIYDevelopment,
    DaysToIntegrateWithLenses,
    DaysToIntegrate,
    TotalDevelopmentCost,
    ROILenses,
    ROIDIY,
    setTableCosts,
    // Features
    features,
    setFeatures,
    // Chart Data
    // chartData,
    // setChartData,
  } = CalculatorLogic()

  const loadSpinner = () => {
    setCurrentPhase({
      ...currentPhase,
      imagePhase: phase_blur,
      loader: true,
    })
  }

  useEffect(() => {
    // Waiting 500ms for UX
    setTimeout(() => {
      if (loader === true) {
        setFeatures(calculateActiveFeatures(calculatorData, features))
      }
    }, 500)
  }, [loader])

  useEffect(() => {
    // Loader updates features => features update Phase
    if (loader === true) {
      setCurrentPhase(
        calculateActivePhase(calculatorData, features, currentPhase)
      )
    }
    window.Mint.logEvent(
      'action=roi_calculator value=' +
        JSON.stringify(calculatorData) +
        'page=' +
        window.location.pathname +
        ' time=' +
        $.now()
    )
  }, [features])

  useEffect(() => {
    // Loader updates features => features update Phase => Phase updates table
    // To Reset initial loader logic to auto load on page land comment out from here and uncomment below
    if (initialLoader) {
      setInitialLoad({
        initialLoader: false,
      })
    }
    if (!loader && !initialLoader) {
      setTableCosts(
        calculateTableCosts(calculatorData, features, currentPhase, tableCosts)
      )
    }
  }, [currentPhase])

  // useEffect(() => {
  //   try {
  //     // Trigger on Page Load
  //     // if (initialLoader) {
  //     //   setInitialLoad({
  //     //     initialLoader: false,
  //     //   })
  //     //   loadSpinner()
  //     // }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // })

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="overflow-hidden container-1 mx-auto">
        <div className="d-flex flex-wrap hero-margin align-items-center">
          <div className="col-lg-5 col-xl-5 col-md-8 col-12 mobile-padding-left">
            <p className="h4-like mb-1">Productivity calculator</p>
            <h1>How much can you boost developer productivity on Kafka?</h1>
            <p className="hero-description-dark pt-4 roboto">
              A great Developer Experience is essential when working with Kafka.
              Enter details of your Kafka project to see what features you need & see
              the productivity savings you can make based on data from 100s of organisations
            </p>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 pt-0 col-md-8 col-12 px-0 mobile-margin-top tablet-margin-top">
            <StaticImage
              className="img-fluid w-100"
              src="../resources/images/kafka_calculator/kafka_to_cash.png"
              placeholder="tracedSVG"
              alt="Kafka calculator"
            />
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          {/* <div className="text-center">
            <p className="h1-like">Do the math</p>
          </div> */}
          <div className="d-flex flex-wrap ">
            <div className="col-12 col-lg-4">
              <CalculatorInputForm
                kafkaProjects={kafkaProjects}
                kafkaManagers={kafkaManagers}
                kafkaDevs={kafkaDevs}
                ratePerDay={ratePerDay}
                dataSources={dataSources}
                levelOfSkill={levelOfSkill}
                criticalServices={criticalServices}
                setCalculatorData={setCalculatorData}
                calculatorData={calculatorData}
                loadSpinner={loadSpinner}
              />
            </div>

            <div className="col ml-5 mt-4 mobile-margin-reset mobile-padding-top">
              {phaseTitle ? (
                <h2 className="text-center">{phaseTitle}</h2>
              ) : (
                <h2 className="text-center">
                  Where are you in your Kafka journey?...
                </h2>
              )}
              <img
                className="w-100 mt-3"
                src={imagePhase}
                alt="Kafka maturity phase"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            {phaseTitle && (
              <p className="h2-like mb-0 pb-3">
                What productivity tools do you need for a {phaseTitle}?
              </p>
            )}
            <div className="d-flex flex-wrap justify-content-center mb-4">
              {phaseText && (
                <div className="col-lg-9">
                  <p>{phaseText}</p>
                </div>
              )}
            </div>
          </div>

          <FeaturesTable features={features} />
        </div>
      </section>
      <Spacing />

      {/* <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-9">
              <p className="text-center h2-like mb-0">
                How long will it take to build these yourself?
              </p>
              <p className="mb-0 text-center">
                These timelines are based on input from the industry and our own
                experience with Kafka. They don’t include the ongoing
                maintenance costs associated with a fully operational platform.
              </p>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-12">
              <ChartComponent
                options={chartData.options ? chartData.options : {}}
                series={chartData.series}
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing /> */}

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-sm-9">
              <p className="text-center h2-like mb-0">
                Productivity ROI calculation
              </p>
              <p className="mb-0 text-center">
                To buy or to build it yourself? That&apos;s the question.
              </p>

              {phaseTitle ? (
                <p className="text-center">
                  This value calculation only focuses on the productivity gained
                  with Lenses. Don&apos;t forget you will also gain the value of
                  your new applications.
                </p>
              ) : (
                <br />
              )}
            </div>
          </div>
        </div>

        <TableCosts
          valuePerYear={valuePerYear}
          ctaLinksTo={ctaLinksTo}
          ctaText={ctaText}
          engineeringManDaysToDevelopTools={engineeringManDaysToDevelopTools}
          CostOfDIYDevelopment={CostOfDIYDevelopment}
          DaysToIntegrateWithLenses={DaysToIntegrateWithLenses}
          DaysToIntegrate={DaysToIntegrate}
          TotalDevelopmentCost={TotalDevelopmentCost}
          ROILenses={ROILenses}
          ROIDIY={ROIDIY}
        />
      </section>

      <span id="quote"></span>

      <Spacing />
      <section className="px-5 mobile-padding-reset mobile-margin-top">
        {phaseNumber === null ? (
          ''
        ) : phaseNumber === 0 ? (
          <div className="container-1">
            <div className="d-flex flex-wrap">
              <div className="col-md-8 offset-sm-2 text-center col-12 offset-0 roll-button">
                <p className="paragraph-title pb-3">
                  Try a single broker Kafka with with Lenses in a docker:
                </p>
                <a className="bg-red text-white " href="/apache-kafka-docker/">
                  <span data-hover="Start">Start</span>
                </a>
                <br />
                <br />
                <a href="/contact-us/" className=" primary-text">
                  Or speak with a Kafka expert{' '}
                  <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <section>
            <div className="container-1 bg-dark rounded-1 mobile-margin-top mobile-radius-reset ipad-radius-reset">
              <div className="d-flex flex-wrap justify-content-center mobile-padding-left mobile-padding-right">
                <div className="py-5 ">
                  <div className="text-center">
                    <h2 className="text-white mb-3 pb-0">
                      Interested? Get a quote
                    </h2>
                  </div>
                  <div className="d-flex flex-wrap">
                    <KafkaROIForm calculatorData={calculatorData} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </section>
      <Spacing />
    </Layout>
  )
}
export default kafkaCalculator
