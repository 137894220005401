import { getActiveFeatures } from './getActiveFeatures'

export const calculateTableCosts = (userInputData, featureslist, currentPhaseTemp, tableCosts) => {
    let activeFeatures = getActiveFeatures(userInputData, featureslist)

    const calculateEngineeringHours = (arrayOfActiveFeatures, featureslist) => {
        let engineeringHoursArray = []
        Object.keys(featureslist).map((key) => {
            if (arrayOfActiveFeatures.includes(key)) {
                engineeringHoursArray.push(featureslist[key].hoursRequiredToBuild)
            }
        })
        // Function to calculate sum
        let engineeringHoursSum = engineeringHoursArray.reduce((a, b) => {
            return a + b
        })
        return engineeringHoursSum
    }

    const calculateValuePerYear = (kafkaDevelopers, kafkaManagers, ratePerDay) => {
        // Kafka Developers * Mins saved per day(30) => Total Mins saved per day
        // Kafka Managers * Mins saved per day(80) => Total Mins saved per day
        // Total Mins saved per day / 60 => Hours saved per day
        // Hours saved per day / 8 => Man days Saved
        // Man days Saved * Rate per day => Daily cost Saved
        // Daily cost Saved * 240 => Annual cost Saved
        let devsTime = ((kafkaDevelopers * 30) / 60 / 8) * ratePerDay * 240
        let kafkaManagersTime = ((kafkaManagers * 240) / 60 / 8) * ratePerDay * 240
        return Math.round(devsTime + kafkaManagersTime)
    }

    const calculateCTAText = (currentPhase) => {
        if (currentPhase.phaseNumber === 0) {
            return 'Free Docker Pull'
        } else {
            return 'Request Quote'
        }
    }

    const calculateDaysWithLenses = (currentPhase) => {
        if (currentPhase.phaseNumber === 0) {
            return 0
        } else if (currentPhase.phaseNumber === 1) {
            return 1
        } else if (currentPhase.phaseNumber === 2) {
            return 3
        } else if (currentPhase.phaseNumber === 3) {
            return 4
        } else if (currentPhase.phaseNumber === 4) {
            return 7
        }
    }
    const calculateDevelopmentCostWithLenses = (currentPhase) => {
        if (currentPhase.phaseNumber === 0) {
            return 0
        } else if (currentPhase.phaseNumber === 1) {
            return 588
        } else if (currentPhase.phaseNumber === 2) {
            return 15000
        } else if (currentPhase.phaseNumber === 3) {
            return 25000
        } else if (currentPhase.phaseNumber === 4) {
            return 50000
        }
    }
    const calculateCTALink = (currentPhase) => {
        if (currentPhase.phaseNumber === 0) {
            return '/apache-kafka-docker/'
        } else {
            return '#quote'
        }
    }

    let tempTableCosts = { ...tableCosts }

    let tempValuePerYear = calculateValuePerYear(
        userInputData.kafkaDevs,
        userInputData.kafkaManagers,
        userInputData.ratePerDay,
    )

    let engineeringHoursToDevelop = calculateEngineeringHours(activeFeatures, featureslist)

    let costOfDIYDevelopment = userInputData.kafkaManagers * userInputData.ratePerDay

    let daysToIntegrate = Math.ceil(engineeringHoursToDevelop / userInputData.kafkaManagers / 8)

    let totalDevelopmentCost = daysToIntegrate * costOfDIYDevelopment

    let numberFormat = Intl.NumberFormat()

    let TotalDevelopmentCostWithLenses = calculateDevelopmentCostWithLenses(currentPhaseTemp)

    tempTableCosts.engineeringHoursToDevelopTools = numberFormat.format(engineeringHoursToDevelop)
    tempTableCosts.engineeringManDaysToDevelopTools = numberFormat.format(
        engineeringHoursToDevelop / 8,
    )
    tempTableCosts.DaysToIntegrate = numberFormat.format(daysToIntegrate)
    tempTableCosts.DaysToIntegrateWithLenses = calculateDaysWithLenses(currentPhaseTemp)
    tempTableCosts.TotalDevelopmentCost = numberFormat.format(Math.ceil(totalDevelopmentCost))
    tempTableCosts.CostOfDIYDevelopment = numberFormat.format(costOfDIYDevelopment)
    tempTableCosts.valuePerYear = numberFormat.format(tempValuePerYear)
    tempTableCosts.ctaText = calculateCTAText(currentPhaseTemp)
    tempTableCosts.ctaLinksTo = calculateCTALink(currentPhaseTemp)
    tempTableCosts.ROILenses = numberFormat.format(
        (tempValuePerYear - TotalDevelopmentCostWithLenses) /
            (TotalDevelopmentCostWithLenses === 0 ? 1 : TotalDevelopmentCostWithLenses),
    )
    tempTableCosts.ROIDIY = (
        (tempValuePerYear - totalDevelopmentCost) /
        totalDevelopmentCost
    ).toFixed(2)

    return tempTableCosts
}
