import { getActiveFeatures } from './getActiveFeatures'

export const calculateActivePhase = (userInputData, featureslist, currentPhaseTemp) => {
    const checkRealTimeOrg = (array, criticalServices) => {
        if (
            array.includes('security') &&
            array.includes('platformAdministration') &&
            array.includes('schemaManagement') &&
            array.includes('governance') &&
            Boolean(criticalServices)
        ) {
            return true
        } else {
            return false
        }
    }
    const checkPlatform = (array, criticalServices) => {
        if (
            array.includes('security') &&
            array.includes('governance') &&
            Boolean(criticalServices)
        ) {
            return true
        } else {
            return false
        }
    }
    const checkTeam = (array, criticalServices) => {
        if (array.includes('security') || Boolean(criticalServices)) {
            return true
        } else return false
    }

    const calculateCurrentPhase = (userInputData, featureslist) => {
        let phaseNo
        let activeFeatures = getActiveFeatures(userInputData, featureslist)
        if (
            activeFeatures.length > 7 &&
            checkRealTimeOrg(activeFeatures, userInputData.criticalServices)
        ) {
            phaseNo = 4
        } else if (
            activeFeatures.length > 5 &&
            checkPlatform(activeFeatures, userInputData.criticalServices)
        ) {
            phaseNo = 3
        } else if (
            activeFeatures.length > 3 &&
            checkTeam(activeFeatures, userInputData.criticalServices)
        ) {
            phaseNo = 2
        } else if (activeFeatures.length > 2) {
            phaseNo = 1
        } else {
            phaseNo = 0
        }
        return phaseNo
    }

    let phase = calculateCurrentPhase(userInputData, featureslist)

    let currentPhase = { ...currentPhaseTemp }
    if (phase === 4) {
        currentPhase.imagePhase = '../../images/phases/phase4.png'
        currentPhase.phaseNumber = 4
        currentPhase.phaseTitle = 'Real-time organization'
        currentPhase.phaseText =
            'Kafka is the central nervous system of your business, making you a rare specimen. Now how can you better manage your platform running costs? Train end-users and onboard teams? Enhance self-service? Scroll to see how you can save costs or fill some feature gaps.'
        currentPhase.loader = false
    } else if (phase === 3) {
        currentPhase.imagePhase = '../../images/phases/phase3.png'
        currentPhase.phaseNumber = 3
        currentPhase.phaseTitle = 'Kafka data platform'
        currentPhase.phaseText =
            'Kafka is stable. Have a cookie 🍪. But the heavy-lifting required to maintain and govern your data is becoming a struggle, and your Platform team are drowning in service tickets. To drive adoption, you’ll need governance and security features. Something like this.'
        currentPhase.loader = false
    } else if (phase === 2) {
        currentPhase.imagePhase = '../../images/phases/phase2.png'
        currentPhase.phaseNumber = 2
        currentPhase.phaseTitle = 'Kafka team'
        currentPhase.phaseText =
            'You’re starting to find a few skeletons in Kafka’s closet; but stick with it. If you’re thinking about how this can be managed more easily across the business, you’re already on the right track. Self-service is the answer: let’s explore your options below.'
        currentPhase.loader = false
    } else if (phase === 1) {
        currentPhase.imagePhase = '../../images/phases/phase1.png'
        currentPhase.phaseNumber = 1
        currentPhase.phaseTitle = 'Kafka project'
        currentPhase.phaseText =
            'You are running a budgeted Kafka project solo or with your small team. Scaling beyond a single project still feels a few miles away, because your business isn’t yet sold on the value of Kafka. See how you can prove it below.'
        currentPhase.loader = false
    } else if (phase === 0) {
        currentPhase.imagePhase = '../../images/phases/phase0.png'
        currentPhase.phaseNumber = 0
        currentPhase.phaseTitle = 'Kafka pilot'
        currentPhase.phaseText =
            'You’re still trying to figure out how Kafka might fit for a particular application, and there’s so much to learn! Keep scrolling and see what you need and how you can scope it out.'
        currentPhase.loader = false
    }

    return currentPhase
}
