import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { displayRangeInputNumbers } from '../../helpers/kafkaCalculator/handleRangeInputs'
import arrow_down from '../../resources/images/kafka_calculator/arrow_down.svg'
import arrow_up from '../../resources/images/kafka_calculator/arrow_up.svg'

const CalculatorInputForm = ({
    kafkaProjects,
    kafkaManagers,
    kafkaDevs,
    ratePerDay,
    dataSources,
    levelOfSkill,
    criticalServices,
    setCalculatorData,
    calculatorData,
    loadSpinner,
}) => {
    useEffect(() => {
        try {
            // console.log(document.querySelector('#averageRatePerDay'))
            displayRangeInputNumbers(
                document.querySelector('#averageRatePerDay'),
                document.querySelector('#averageRatePerDayValue'),
            )
            displayRangeInputNumbers(
                document.querySelector('#projectsOnKafka'),
                document.querySelector('#projectsOnKafkaValue'),
            )
            displayRangeInputNumbers(
                document.querySelector('#dataSourcesOnKafka'),
                document.querySelector('#dataSourcesOnKafkaValue'),
            )
        } catch (err) {
            console.log(err)
        }
    })

    const onChange = (e) => {
        // Set as Number
        if (
            e.target.name === 'ratePerDay' ||
            e.target.name === 'dataSources' ||
            e.target.name === 'kafkaProjects' ||
            e.target.name === 'kafkaDevs' ||
            e.target.name === 'kafkaManagers'
        ) {
            setCalculatorData({
                ...calculatorData,
                [e.target.name]: e.target.valueAsNumber,
            })
        }
        // Set as Boolean
        else if (e.target.name === 'criticalServices') {
            if (e.target.value === 'true') {
                setCalculatorData({
                    ...calculatorData,
                    [e.target.name]: true,
                })
            } else {
                setCalculatorData({
                    ...calculatorData,
                    [e.target.name]: false,
                })
            }
            // Set as String
        } else {
            setCalculatorData({ ...calculatorData, [e.target.name]: e.target.value })
        }
        // Loading Spinner also triggers Effect Hooks
        loadSpinner()
    }

    // State Updates
    const calculatorDataIncreaseDevsNumber = (e) => {
        e.preventDefault()
        setCalculatorData({ ...calculatorData, kafkaDevs: Number(kafkaDevs) + 1 })
        loadSpinner()
    }
    const calculatorDataDecreaseDevsNumber = (e) => {
        e.preventDefault()
        if (kafkaDevs > 1) {
            setCalculatorData({ ...calculatorData, kafkaDevs: Number(kafkaDevs) - 1 })
            loadSpinner()
        }
    }
    const calculatorDataIncreaseManagerNumber = (e) => {
        e.preventDefault()
        setCalculatorData({
            ...calculatorData,
            kafkaManagers: Number(kafkaManagers) + 1,
        })
        loadSpinner()
    }
    const calculatorDataDecreaseManagerNumber = (e) => {
        e.preventDefault()
        if (kafkaManagers > 1) {
            setCalculatorData({
                ...calculatorData,
                kafkaManagers: Number(kafkaManagers) - 1,
            })
            loadSpinner()
        }
    }

    return (
        <form action='#'>
            <label htmlFor='projectsOnKafka'>
                <p className='mb-0 fw-700'>
                    Projects on Kafka{' '}
                    <span className='d-none d-md-inline f-15'>
                        <a
                            href='#!'
                            className='tooltip-top'
                            data-tooltip='Could be a product, a team or a set of data flows for an internal use case.'>
                            <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                        </a>
                    </span>
                </p>
            </label>
            <div className='position-relative'>
                <div id='projectsOnKafkaValueMin'></div>
                <div id='projectsOnKafkaValueMax'></div>
                <span className='calculator-rangebar-span'>
                    <span id='projectsOnKafkaValue'></span>
                </span>
                <input
                    type='range'
                    min='1'
                    max='20'
                    step='1'
                    name='kafkaProjects'
                    value={kafkaProjects}
                    onChange={(e) => onChange(e)}
                    id='projectsOnKafka'
                    className='calculator-range'
                />
            </div>
            <p className='fw-700 mt-4'>
                People dependent on Kafka{' '}
                <span className='d-none d-md-inline f-15'>
                    <a
                        href='#!'
                        className='tooltip-top'
                        data-tooltip='This helps approximate  the scale of your project and your operating costs.'>
                        <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                    </a>
                </span>
            </p>
            <div className='d-flex flex-wrap'>
                <div className='col pl-0'>
                    <label htmlFor='KafkaManagers'>
                        <p className='mb-0 f-14'>
                            Platform Engineers{' '}
                            <span className='d-none d-md-inline f-15'>
                                <a
                                    href='#!'
                                    className='tooltip-top'
                                    data-tooltip='Platform engineers operating or monitoring Kafka'>
                                    <i
                                        className='fa fa-info-circle f-15 opct-6'
                                        aria-hidden='true'></i>
                                </a>
                            </span>
                        </p>
                    </label>

                    <div className='quantity'>
                        <input
                            type='number'
                            className='w-50 pl-4  py-1 number-input'
                            name='kafkaManagers'
                            value={kafkaManagers}
                            onChange={(e) => onChange(e)}
                            id='KafkaManagers'
                        />
                        <div className='quantity-nav'>
                            <button
                                onClick={(e) => calculatorDataDecreaseManagerNumber(e)}
                                className='quantity-button quantity-decrease'>
                                <img src={arrow_down} alt='Decrease Platform Engineer' />
                            </button>
                            <button
                                onClick={(e) => calculatorDataIncreaseManagerNumber(e)}
                                className='quantity-button quantity-increase'>
                                <img src={arrow_up} alt='Increase Platform Engineer' />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='col pr-0'>
                    <label htmlFor='kafkaDevs'>
                        <p className='mb-0 f-14'>
                            Developers{' '}
                            <span className='d-none d-md-inline f-15'>
                                <a
                                    href='#!'
                                    className='tooltip-top'
                                    data-tooltip='Developers building applications on Kafka'>
                                    <i
                                        className='fa fa-info-circle f-15 opct-6'
                                        aria-hidden='true'></i>
                                </a>
                            </span>
                        </p>
                    </label>
                    <div className='quantity'>
                        <input
                            type='number'
                            className='form-control-range w-50 pl-4 py-1 number-input'
                            name='kafkaDevs'
                            value={kafkaDevs}
                            onChange={(e) => onChange(e)}
                            id='kafkaDevs'
                        />
                        <div className='quantity-nav'>
                            <button
                                className='quantity-button quantity-decrease'
                                onClick={(e) => calculatorDataDecreaseDevsNumber(e)}>
                                <img src={arrow_down} alt='Decrease Developers' />
                            </button>
                            <button
                                className='quantity-button quantity-increase'
                                onClick={(e) => calculatorDataIncreaseDevsNumber(e)}>
                                <img src={arrow_up} alt='Increase Developers' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <label htmlFor='averageRatePerDay'>
                <p className='mb-0 mt-2'>
                    Their average rate per day ${' '}
                    <span className='d-none d-md-inline f-15'>
                        <a
                            href='#!'
                            className='tooltip-top'
                            data-tooltip='This counts towards your Kafka costs. '>
                            <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                        </a>
                    </span>
                </p>
            </label>
            <div className='position-relative'>
                {/* Also have CSS values */}
                <div id='averageRatePerDayValueMin'></div>
                <div id='averageRatePerDayValueMax'></div>
                <span className='calculator-rangebar-span'>
                    <span id='averageRatePerDayValue'></span>
                </span>
                <input
                    type='range'
                    className='calculator-range'
                    min='200'
                    max='2000'
                    step='100'
                    name='ratePerDay'
                    value={ratePerDay}
                    onChange={(e) => onChange(e)}
                    id='averageRatePerDay'
                />
            </div>

            {/* DATA SOURCES */}
            <label htmlFor='dataSourcesOnKafka'>
                <p className='mb-0 fw-700 mt-4'>
                    Data sources connected to your Kafka{' '}
                    <span className='d-none d-md-inline f-15'>
                        <a
                            href='#!'
                            className='tooltip-top'
                            data-tooltip='No. topics associated with microservices & data integrations.'>
                            <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                        </a>
                    </span>
                </p>
            </label>
            <div className='position-relative'>
                <div id='dataSourcesOnKafkaValueMin'></div>
                <div id='dataSourcesOnKafkaValueMax'></div>
                <span className='calculator-rangebar-span'>
                    <span id='dataSourcesOnKafkaValue'></span>
                </span>
                <input
                    type='range'
                    className='calculator-range'
                    min='0'
                    max='20'
                    step='1'
                    name='dataSources'
                    value={dataSources}
                    onChange={(e) => onChange(e)}
                    id='dataSourcesOnKafka'
                />
            </div>
            {/* END OF DATA SOURCES */}

            <p className='mb-0 fw-700 mt-4 mb-3'>
                How skilled are the developers at stream processing?{' '}
                <span className='d-none d-md-inline f-15'>
                    <a
                        href='#!'
                        className='tooltip-top'
                        data-tooltip='Stream processing is a paradigm shift and requires skills and approaches different from traditional development.'>
                        <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                    </a>
                </span>
            </p>

            <input
                type='radio'
                name='levelOfSkill'
                defaultValue='Not-very'
                id='Not-very'
                className='cursor-pointer'
                checked={levelOfSkill === 'Not-very'}
                onChange={(e) => onChange(e)}
            />
            <label className='pl-1 p-text' htmlFor='Not-very'>
                Not very
            </label>
            <input
                className='ml-3 cursor-pointer'
                type='radio'
                name='levelOfSkill'
                id='A-bit'
                defaultValue='A-bit'
                onChange={(e) => onChange(e)}
            />
            <label className='pl-1 p-text' htmlFor='A-bit'>
                A bit
            </label>
            <input
                className='ml-3 cursor-pointer'
                type='radio'
                name='levelOfSkill'
                id='Very'
                defaultValue='Very'
                onChange={(e) => onChange(e)}
            />
            <label className='pl-1 p-text' htmlFor='Very'>
                Very
            </label>

            <p className='mb-0 fw-700 mt-3'>
                Are you running critical services on Kafka?{' '}
                <span className='d-none d-md-inline f-15'>
                    <a
                        href='#!'
                        className='tooltip-top'
                        data-tooltip='Are the services in production, and are they an S1 or S2 service?'>
                        <i className='fa fa-info-circle f-15 opct-6' aria-hidden='true'></i>
                    </a>
                </span>
            </p>
            <br />

            <input
                type='radio'
                className='cursor-pointer'
                name='criticalServices'
                id='No'
                defaultValue={false}
                defaultChecked={criticalServices === false || criticalServices === 'false'}
                onClick={(e) => onChange(e)}
            />
            <label className='pl-1 p-text' htmlFor='No'>
                No
            </label>
            <input
                className='ml-3 cursor-pointer'
                type='radio'
                name='criticalServices'
                id='Yes'
                defaultValue={true}
                onClick={(e) => onChange(e)}
            />
            <label className='pl-1 p-text' htmlFor='Yes'>
                Yes
            </label>

            <br />
        </form>
    )
}

export default CalculatorInputForm

CalculatorInputForm.propTypes = {
    kafkaProjects: PropTypes.number,
    kafkaManagers: PropTypes.number,
    kafkaDevs: PropTypes.number,
    ratePerDay: PropTypes.number,
    dataSources: PropTypes.number,
    levelOfSkill: PropTypes.string,
    criticalServices: PropTypes.bool,
    setCalculatorData: PropTypes.func,
    calculatorData: PropTypes.object,
    loadSpinner: PropTypes.func,
}
