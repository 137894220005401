import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { getClientId } from '../../helpers/getClientId'
import ZoominfoFields from './Zoominfo-fields'
import callZoominfo from '../../helpers/zoomInfo'
import { getCookieByName } from '../../helpers/getCookieByName'

const kafkaRoiForm = ({ calculatorData }) => {
    useEffect(() => {
        // Zoominfo Snippet
        callZoominfo('uoOiaHNN8N3QdXczOrJm')

        // Getting clientId
        const interval = setInterval(function () {
            if (getClientId()) {
                let gaClientId = getClientId()
                document.querySelector('#ga_client_id').value = gaClientId
                clearInterval(interval)
            }
        }, 1000)

        // es-lint-disable-next-line
    }, [])

    let passedRecaptcha = false

    const validateEmail = (e) => {
        const emailValue = e.target.value
        const email = document.getElementById('email')
        // console.log(email)
        if (
            emailValue.includes('gmail.com') ||
            emailValue.includes('yahoo.com') ||
            emailValue.includes('hotmail.com')
        ) {
            email.classList.add('is-invalid')
            document.getElementById('formsubmit').disabled = true
        } else {
            email.classList.remove('is-invalid')
            if (passedRecaptcha === true) {
                document.getElementById('formsubmit').disabled = false
            }
        }
    }

    const onSubmit = () => {
        document.querySelector('#splunk_id').value = getCookieByName('mintjs%3Auuid')
        document.getElementById('formsubmit').disabled = true
    }

    const handleFormRecaptcha = () => {
        passedRecaptcha = true
        document.getElementById('formsubmit').disabled = false
    }

    return (
        <div>
            {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
            <form
                id='zoominfo-all-fields-form'
                action='https://go.pardot.com/l/877242/2021-01-14/4hn2r7'
                method='POST'
                onSubmit={onSubmit}>
                <ZoominfoFields includeCompany={true} includePhone={true} includeJobTitle={true} />
                <input
                    className='m-2 form-control p-4 rounded25 form-border mobile-margin-reset'
                    type='text'
                    required
                    maxLength='80'
                    name='First Name'
                    placeholder='First Name*'
                />

                <input
                    className='m-2 form-control p-4 rounded25 form-border mobile-margin-reset'
                    type='text'
                    required
                    maxLength='80'
                    name='Last Name'
                    placeholder='Last Name*'
                />

                <div className='mobile-padding-top-half'></div>
                <input
                    className='m-2 form-control p-4 form-border rounded25 mobile-margin-reset'
                    id='email'
                    type='email'
                    required
                    maxLength='100'
                    name='Email'
                    placeholder='Work Email*'
                    onKeyUp={(e) => validateEmail(e)}
                />
                <div className='invalid-feedback ml-2'>Please provide your professional email</div>

                <div className='mobile-padding-top-half'></div>
                <div className='input-group m-2 mobile-margin-reset'>
                    <select
                        className='my-1 bg-white  rounded25 pl-4 countries-dropdown'
                        name='Country Code'>
                        <option value=''>Country</option>
                        <option value='AFG&#x20;&#x2b;93'>AFG +93</option>
                        <option value='ALB&#x20;&#x2b;355'>ALB +355</option>
                        <option value='ARG&#x20;&#x2b;54'>ARG +54</option>
                        <option value='AUS&#x20;&#x2b;61'>AUS +61</option>
                        <option value='AUT&#x20;&#x2b;43'>AUT +43</option>
                        <option value='BEL&#x20;&#x2b;32'>BEL +32</option>
                        <option value='BIH&#x20;&#x2b;387'>BIH +387</option>
                        <option value='BRA&#x20;&#x2b;55'>BRA +55</option>
                        <option value='BG&#x20;&#x2b;359'>BGR +359</option>
                        <option value='CA&#x20;&#x2b;1'>CAN +1</option>
                        <option value='CH&#x20;&#x2b;41'>CHE +41</option>
                        <option value='CHL&#x20;&#x2b;56'>CHL +56</option>
                        <option value='CN&#x20;&#x2b;86'>CHN +86</option>
                        <option value='CO&#x20;&#x2b;57'>COL +57</option>
                        <option value='CR&#x20;&#x2b;506'>CRI +506</option>
                        <option value='CY&#x20;&#x2b;357'>CYP +357</option>
                        <option value='CZ&#x20;&#x2b;420'>CZE +420</option>
                        <option value='DE&#x20;&#x2b;49'>DEU +49</option>
                        <option value='DK&#x20;&#x2b;45'>DNK +45</option>
                        <option value='EG&#x20;&#x2b;20'>EGY +20</option>
                        <option value='ES&#x20;&#x2b;34'>ESP +34</option>
                        <option value='EE&#x20;&#x2b;372'>EST +372</option>
                        <option value='FI&#x20;&#x2b;358'>FIN +358</option>
                        <option value='FR&#x20;&#x2b;33'>FRA +33</option>
                        <option value='GR&#x20;&#x2b;30'>GRC +30</option>
                        <option value='HR&#x20;&#x2b;385'>HRV +385</option>
                        <option value='IND&#x20;&#x2b;91'>IND +91</option>
                        <option value='IS&#x20;&#x2b;354'>ISL +354</option>
                        <option value='IT&#x20;&#x2b;39'>ITA +39</option>
                        <option value='JP&#x20;&#x2b;81'>JPN +81</option>
                        <option value='NL&#x20;&#x2b;31'>NLD +31</option>
                        <option value='NZ&#x20;&#x2b;64'>NZL +64</option>
                        <option value='NG&#x20;&#x2b;234'>NGA +234</option>
                        <option value='NO&#x20;&#x2b;47'>NOR +47</option>
                        <option value='PT&#x20;&#x2b;351'>PRT +351</option>
                        <option value='RU&#x20;&#x2b;7'>RUS +7</option>
                        <option value='TR&#x20;&#x2b;90'>TUR +90</option>
                        <option value='UA&#x20;&#x2b;380'>UKR +380</option>
                        <option value='UK&#x20;&#x2b;44'>UK +44</option>
                        <option value='US&#x20;&#x2b;1'>USA +1</option>
                        <option value='other'>Other</option>
                    </select>

                    <input
                        className='my-1 ml-0 form-control input-group-append rounded25  p-4 border-left-0'
                        type='text'
                        maxLength='30'
                        name='Phone'
                        placeholder='Phone Number*'
                        required
                    />
                </div>
                {/* <!-- Phone & country ^ --> */}

                <input name='GA Client ID' type='hidden' className='d-none' id='ga_client_id' />

                <input type='hidden' name='Splunk ID' id='splunk_id' className='d-none' />

                <textarea
                    id='CalculatorData'
                    name='CalculatorData'
                    className='d-none'
                    defaultValue={JSON.stringify(calculatorData)}></textarea>

                <div className='text-white m-2 mobile-padding-top-half'>
                    <label className='d-none' htmlFor='Terms Of Use'>
                        {' '}
                        Terms of use and Privacy Policy
                    </label>
                    <input type='checkbox' name='Terms Of Use' required />{' '}
                    <span className='text-white pl-1'>
                        By submitting the form, you agree to our{' '}
                        <a className='text-white link-text' href='/legals/terms-and-conditions/'>
                            Terms of Use{' '}
                        </a>
                        and{' '}
                        <a className='text-white link-text' href='/legals/policy/'>
                            Privacy Policy
                        </a>
                    </span>
                </div>
                <ReCAPTCHA
                    className='ml-2 '
                    sitekey='6LfNhSEUAAAAAL9OJM7RO856GguxnnKDM-uveavH'
                    onChange={handleFormRecaptcha}
                />
                <div>
                    <div className='mobile-padding-top-half'></div>
                    <input
                        className='ml-2 my-3 border-0 py-2 bg-red text-white f-16 fw-600 w-100 rounded25 opct-10 mobile-margin-reset'
                        id='formsubmit'
                        type='submit'
                        defaultValue='Send now'
                        disabled></input>
                </div>
            </form>
        </div>
    )
}

export default kafkaRoiForm

kafkaRoiForm.propTypes = {
    calculatorData: PropTypes.object,
}
