import React from 'react'
import PropTypes from 'prop-types'

const featuresTable = ({ features }) => {
    return (
        <div className='d-flex flex-wrap'>
            <div className='col'>
                <div className='table-responsive'>
                    <table className='table table-bordered table-border-calculator'>
                        <thead>
                            <tr className='text-center cera-pro f-24 fw-500 footer-color'>
                                <th className='px-2 py-4' scope='col'>
                                    Data exploration
                                </th>
                                <th className='px-2 py-4' scope='col'>
                                    Data processing
                                </th>
                                <th className='px-2 py-4' scope='col'>
                                    Monitoring & observability
                                </th>
                                <th className='px-2 py-4' scope='col'>
                                    Self-service admin
                                </th>
                                <th className='px-2 py-4' scope='col'>
                                    Security & governance
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='border-bottom-0 position-relative pb-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-right cursor-default'
                                        data-tooltip='Enrich, filter and explore streaming data and metadata without code'>
                                        <img
                                            className='feature-image d-inline'
                                            src={features.dataExploration.image}
                                            alt='Data Exploration'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.dataExploration.active}`}>
                                        {features.dataExploration.name.toUpperCase()}
                                    </p>
                                </td>
                                <td className='border-bottom-0 position-relative pb-0'>
                                    <a
                                        href='/product/sql/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='tooltip-top'
                                        data-tooltip='Aggregate, transform and reshape streaming data with SQL.'>
                                        <img
                                            className='feature-image'
                                            src={features.streamingSQL.image}
                                            alt='streaming SQL'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.streamingSQL.active}`}>
                                        <a
                                            className={`feature-text-${
                                                features.streamingSQL.active
                                            } ${features.streamingSQL.active ? 'fw-500' : ''}`}
                                            href='/product/sql/'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            {features.streamingSQL.name.toUpperCase()}
                                            <i
                                                className='fa fa-external-link pl-2 f-10'
                                                aria-hidden='true'></i>
                                        </a>
                                    </p>
                                </td>
                                <td className='border-bottom-0 position-relative pb-0'>
                                    <a
                                        href='/usecases/kafka-monitoring/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='tooltip-top'
                                        data-tooltip='View the health of your whole Kafka environment including Kafka Connect and Schema Registry.'>
                                        <img
                                            className='feature-image'
                                            src={features.infrastructureMonitoring.image}
                                            alt='Infra Monitoring'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.infrastructureMonitoring.active}`}>
                                        <a
                                            className={`feature-text-${
                                                features.infrastructureMonitoring.active
                                            } ${
                                                features.infrastructureMonitoring.active
                                                    ? 'fw-500'
                                                    : ''
                                            }`}
                                            href='/usecases/kafka-monitoring/'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            {features.infrastructureMonitoring.name.toUpperCase()}
                                            <i
                                                className='fa fa-external-link pl-2 f-10'
                                                aria-hidden='true'></i>
                                        </a>
                                    </p>
                                </td>
                                <td className='border-bottom-0 position-relative pb-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-top cursor-default'
                                        data-tooltip='Manage schemas for all data types including AVRO, JSON, XML. Connect to 3rd party schema registries such as Confluent and Cloudera.'>
                                        <img
                                            className='feature-image'
                                            src={features.schemaManagement.image}
                                            alt='Schema Management'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.schemaManagement.active}`}>
                                        {features.schemaManagement.name.toUpperCase()}
                                    </p>
                                </td>
                                <td className='border-bottom-0 position-relative pb-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-left cursor-default'
                                        data-tooltip='Provide multi-tenant platform access with enterprise security, RBAC and audit logs.'>
                                        <img
                                            className='feature-image'
                                            src={features.security.image}
                                            alt='Security'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.security.active}`}>
                                        {features.security.name.toUpperCase()}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className='border-top-0 pt-0'></td>
                                <td className='border-top-0 pt-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-top cursor-default'
                                        data-tooltip='Deploy data across different data stores e.g. Elasticsearch, Postgres.'>
                                        <img
                                            className='feature-image'
                                            src={features.deployingConnectors.image}
                                            alt='Deploy Connectors'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.deployingConnectors.active}`}>
                                        {features.deployingConnectors.name.toUpperCase()}
                                    </p>
                                </td>
                                <td className='border-top-0 pt-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-top cursor-default'
                                        data-tooltip='View real time performance metrics of flows and microservices, including throughput and lag.'>
                                        <img
                                            className='feature-image'
                                            src={features.flowsMonitoring.image}
                                            alt='Flows monitoring'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.flowsMonitoring.active}`}>
                                        {features.flowsMonitoring.name.toUpperCase()}
                                    </p>
                                </td>
                                <td className='border-top-0 pt-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-top cursor-default'
                                        data-tooltip='Modify and keep track of configuration across your entire Kafka platform in a self-service fashion.'>
                                        <img
                                            className='feature-image'
                                            src={features.platformAdministration.image}
                                            alt='Platform Administration'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.platformAdministration.active}`}>
                                        {features.platformAdministration.name.toUpperCase()}
                                    </p>
                                </td>
                                <td className='border-top-0 pt-0'>
                                    <a
                                        href='#!'
                                        className='tooltip-left cursor-default'
                                        data-tooltip='Self-service features such as fine-grained permissions and user/ quota mgt, as well as data lineage and masking.'>
                                        <img
                                            className='feature-image'
                                            src={features.governance.image}
                                            alt='Governance'
                                        />
                                    </a>
                                    <p
                                        className={`text-center cera-pro feature-text-${features.governance.active}`}>
                                        {features.governance.name.toUpperCase()}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default featuresTable

featuresTable.propTypes = {
    features: PropTypes.object,
}
