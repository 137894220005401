import React from 'react'
import PropTypes from 'prop-types'

const TableCosts = ({
    valuePerYear,
    ctaLinksTo,
    ctaText,
    engineeringManDaysToDevelopTools,
    CostOfDIYDevelopment,
    DaysToIntegrateWithLenses,
    DaysToIntegrate,
    TotalDevelopmentCost,
    ROILenses,
    ROIDIY,
}) => {
    return (
        <div className='container-1'>
            <div className='d-flex flex-wrap'>
                <div className='col'>
                    <div className='table-responsive'>
                        <table className='table table-bordered table-border-calculator'>
                            <thead>
                                <tr className='text-center cera-pro f-24 fw-500 footer-color'>
                                    <th className='px-2 py-4' scope='col'>
                                        Costs
                                    </th>
                                    <th className='px-2 py-4' scope='col'>
                                        Lenses.io
                                    </th>
                                    <th className='px-2 py-4' scope='col'>
                                        DIY
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='border-y-0 pb-0'>
                                        <p className='fw-600'>
                                            Value you gain per year{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='Based on value assessments with Lenses.io customers.'>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-y-0 pb-0'>
                                        <p className='text-center fw-600'>
                                            {valuePerYear ? `$${valuePerYear}` : '-'}
                                        </p>
                                    </td>
                                    <td className='border-y-0 pb-0'>
                                        <p className='text-center fw-600'>
                                            {valuePerYear ? `$${valuePerYear}` : '-'}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-y-0 pb-0 pt-3 '>
                                        <p> Buying Lenses</p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <div className='text-center fw-600'>
                                            {ctaLinksTo ? (
                                                <div className='roll-button'>
                                                    <a
                                                        href={ctaLinksTo}
                                                        className='bg-red text-white'>
                                                        <span data-hover={`${ctaText}`}>
                                                            {ctaText}
                                                        </span>
                                                    </a>
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center fw-600'>-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-y-0 py-0'>
                                        <p>
                                            Man-days required to develop tools{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='Time taken to develop features for your level of Kafka maturity, based on input from our own engineering teams who build these features.'>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center '>
                                            {' '}
                                            {engineeringManDaysToDevelopTools ? `0 days` : '-'}
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center'>
                                            {engineeringManDaysToDevelopTools
                                                ? `${engineeringManDaysToDevelopTools} days`
                                                : '-'}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-y-0 py-0'>
                                        <p>
                                            Daily cost of DIY development{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='The daily rate times the number of platform engineers provided.'>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center fw-600'>-</p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center'>
                                            {CostOfDIYDevelopment
                                                ? `$${CostOfDIYDevelopment}`
                                                : '-'}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-y-0 py-0'>
                                        <p>
                                            Days to integrate{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='The number of man days required to develop features divided by number of available platform engineers. Based on 8-hour days. '>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center'>
                                            {DaysToIntegrateWithLenses === 0
                                                ? `${DaysToIntegrateWithLenses} days`
                                                : DaysToIntegrateWithLenses === 1
                                                  ? `${DaysToIntegrateWithLenses} day`
                                                  : DaysToIntegrateWithLenses > 0
                                                    ? `${DaysToIntegrateWithLenses} days`
                                                    : '-'}
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center'>
                                            {DaysToIntegrate ? `${DaysToIntegrate} days` : '-'}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-y-0 py-0'>
                                        <p>
                                            Total development cost{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='The total number of man-days (based on 8-hour working day) times the daily rate of an engineer.'>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center fw-600'>
                                            {TotalDevelopmentCost ? 0 : '-'}
                                        </p>
                                    </td>
                                    <td className='border-y-0 py-0'>
                                        <p className='text-center fw-600'>
                                            {TotalDevelopmentCost
                                                ? `$${TotalDevelopmentCost}`
                                                : '-'}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-top-0 py-0'>
                                        <p className='fw-600'>
                                            Increased productivity ROI{' '}
                                            <span className='d-none d-md-inline'>
                                                <a
                                                    href='#!'
                                                    className='tooltip-right'
                                                    data-tooltip='The estimated value of a solution minus the estimated cost divided by the estimated cost'>
                                                    <i
                                                        className='fa fa-info-circle f-15 opct-6'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </span>
                                        </p>
                                    </td>
                                    <td className='border-top-0 py-0'>
                                        <p className='text-center fw-600'>
                                            {ROILenses ? `${ROILenses}x` : '-'}
                                        </p>
                                    </td>
                                    <td className='border-top-0 py-0'>
                                        <p className='text-center fw-600'>
                                            {ROIDIY ? `${ROIDIY}x` : '-'}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="text-right">
          <p className="f-14">
            *Based on feedback from over 1000 existing Lenses users
          </p>
        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableCosts

TableCosts.propTypes = {
    valuePerYear: PropTypes.string,
    ctaLinksTo: PropTypes.string,
    ctaText: PropTypes.string,
    engineeringManDaysToDevelopTools: PropTypes.string,
    CostOfDIYDevelopment: PropTypes.string,
    DaysToIntegrateWithLenses: PropTypes.number,
    DaysToIntegrate: PropTypes.string,
    TotalDevelopmentCost: PropTypes.string,
    ROILenses: PropTypes.string,
    ROIDIY: PropTypes.string,
}
