export function displayRangeInputNumbers(myRange, myValue) {
    let off = myRange.offsetWidth / (parseInt(myRange.max) - parseInt(myRange.min))
    let px =
        (myRange.valueAsNumber - parseInt(myRange.min)) * off - myValue.offsetParent.offsetWidth / 2

    // Exclude min max

    myValue.parentElement.style.left = px + 'px'
    myValue.innerHTML = myRange.value

    myRange.oninput = function () {
        let px = (myRange.valueAsNumber - parseInt(myRange.min)) * off - myValue.offsetWidth / 2
        myValue.innerHTML = myRange.value
        myValue.parentElement.style.left = px + 'px'
    }

    // Set min-max values in UI
    const setMinMaxValuesInRangeBar = (value) => {
        let min = value.id + 'Min'
        document.getElementById(min).innerHTML = parseInt(myRange.min)
        let max = value.id + 'Max'
        document.getElementById(max).innerHTML = parseInt(myRange.max) + '+'
    }
    setMinMaxValuesInRangeBar(myValue)

    // Hide values when bar reaches min or max
    // Since those values are displayed by min-max that we set above
    const handleMinMaxValuesInRangeBar = (value) => {
        if (
            value.innerHTML === myRange.max ||
            value.innerHTML === myRange.min ||
            value.innerHTML === ''
        ) {
            value.innerHTML = ''
        }
    }

    // Handle Opacities
    const handleMinMaxValuesOpacities = (value) => {
        let min = value.id + 'Min'
        let max = value.id + 'Max'

        if (value.innerHTML === myRange.max) {
            document.getElementById(max).style.opacity = 1
        } else if (value.innerHTML === myRange.min) {
            document.getElementById(min).style.opacity = 1
        } else {
            document.getElementById(min).style.opacity = 0.5
            document.getElementById(max).style.opacity = 0.5
        }

        handleMinMaxValuesInRangeBar(value)
    }

    handleMinMaxValuesOpacities(myValue)
}
