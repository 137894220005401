export const getActiveFeatures = (userInputData, featureslist) => {
    const checkForDuplicateConditions = (array) => {
        let instancesOfStreamingSQL = 0
        array.map((element, index) => {
            if (element.includes('streamingSQL') && instancesOfStreamingSQL > 0) {
                array.splice(index, 1)
            }
            if (element.includes('streamingSQL') && instancesOfStreamingSQL === 0) {
                instancesOfStreamingSQL++
            }
        })
        if (array.includes('deployingConnectors') && array.includes('deployingConnectors2')) {
            // Remove deployingConnectors2 if both exist
            const index = array.indexOf('deployingConnectors2')
            if (index > -1) {
                array.splice(index, 1)
            }
        }
        // console.log(array)
        return array
    }

    const handleConditionBooleanValue = (condition, userInputData) => {
        let conditionName = condition.split(' ')[0]
        let operator = condition.split(' ')[1]
        let conditionValue = condition.split(' ')[2]
        if (operator === '>') {
            return Number(userInputData[conditionName]) > Number(conditionValue)
        } else if (operator === '===') {
            return userInputData[conditionName] === conditionValue
        } else if (operator === '<') {
            return Number(userInputData[conditionName]) < Number(userInputData[conditionValue])
        }
    }

    try {
        let arrayOfTrueConditions = []
        Object.keys(featureslist).map((key) => {
            let stateOfConditions = []
            featureslist[key].requirements.map((condition) => {
                stateOfConditions.push(handleConditionBooleanValue(condition, userInputData))
            })
            // Filter for only true
            const isTrue = (value) => value === true
            if (stateOfConditions.every(isTrue)) {
                arrayOfTrueConditions.push(key)
                arrayOfTrueConditions = checkForDuplicateConditions(arrayOfTrueConditions)
            }
        })
        return arrayOfTrueConditions
    } catch (error) {
        console.log(error)
    }
}
